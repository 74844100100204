
import { defineComponent, ref, toRefs, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { string } from "yup/lib/locale";

interface Factor {  
  Id : number
  Name : string
  Des : string
  Higher : number 
  Lower : number
  RulesId : number
}

export default defineComponent({
  name: "create-papi-kostick-factor-component",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    isopenmodal:Boolean,
    tipeTestId: String,
  },
  setup(props, {emit}) {
    const { isopenmodal } = toRefs(props);
    let dialogAddGroup = ref(false)
    var selected = ref(0);
    var rulesIdCache = ref(0)
    var factorCache = ref([] as Array<any>)
    const submitButton = ref<HTMLElement | null>(null);
    var factor = ref<Factor>({
                Id : 0,
                Name : "",
                Des : "",
                Higher : 0, 
                Lower : 0,
                RulesId : 0,
              })

    function resetDataFactor(){
        factor.value.Id = 0
        factor.value.Name = ""
        factor.value.Des = ""
        factor.value.Higher	= 0
        factor.value.Lower	=  0
        factor.value.RulesId = 0
    }
    
    watch(isopenmodal, (newVal, oldVal) => {
      resetDataFactor()

      if(newVal){
        Promise.all([getRule()]).then((values) => {
            Promise.all([getFactor()]).then((values) => {
                dialogAddGroup.value = true
                selected.value = 0 
            });
        });
      }
    });

    watch(selected, (newVal, oldVal) => {
      resetDataFactor()

      var foundObj = factorCache.value.findFirst(newVal, 'Id')
      if(foundObj != undefined){
        factor.value.Id = foundObj.Id
        factor.value.Name = foundObj.Name
        factor.value.Des = foundObj.Des
        factor.value.Higher	= foundObj.Higher
        factor.value.Lower	=  foundObj.Lower
        factor.value.RulesId = foundObj.RulesId
      }
    });

    async function getFactor(){
      factorCache.value = []
      await Services.GetData(ApiService2, `master/rules-papi-kostick-values`, rulesIdCache.value, response=>{
          factorCache.value = response.data
          factorCache.value = factorCache.value.orderBy(["Name", "Lower"], "ASC")
          factorCache.value.unshift({
              Id : 0,
              Name : "Buat Baru",
              Des : "Buat Baru",
              Higher : 0, 
              Lower : 0,
              RulesId : rulesIdCache.value,
            })
      }, err =>{
        err
      })
    }

    async function getRule(){
      rulesIdCache.value = 0
      await Services.GetData(ApiService2, `master/papi-kostick-map/map`, props.tipeTestId, response=>{
          rulesIdCache.value = response.data[0].RulesId
      }, err =>{
        err
      })
    }

    function modalClose(){
      dialogAddGroup.value = false
      emit("modalClose", true);
    }

    const factorValidator = Yup.object().shape({
      fname: Yup.string().required().label("Faktor"),
      flower: Yup.string().required().label("Nilai Terendah"),
      fhigher: Yup.string().required().label("Nilai Tertinggi"),
      fdes: Yup.string().required().label("Deskripsi"),
    });

    function saveForm (data_save){
      var saveObj = {}
      saveObj = Object.assign({}, data_save._rawValue)
      saveObj['RulesId'] = rulesIdCache.value

      if(saveObj['Id'] != 0){
        update(saveObj)
      }else{
        save(saveObj)
      }
    };

    async function save(saveObj){
      var responseCache = []
      await Services.PostData(ApiService2, `master/rules-papi-kostick-values/`, saveObj, response=>{
        responseCache = response.data
        toast("Save Berhasil!", 
        {
          autoClose: 1000,
          type: 'success',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        getFactor()
        selected.value = 0
      }, err =>{
        err
        toast("Save Gagal", 
        {
          autoClose: 1000,
          type: 'error',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        getFactor()
        selected.value = 0
      })
    }

    async function update(saveObj){
      var responseCache = []
      await Services.PutData(ApiService2,  `master/rules-papi-kostick-values/${saveObj.Id}`, saveObj, response=>{
        responseCache = response.data
        toast("Save Berhasil!", 
        {
          autoClose: 1000,
          type: 'success',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        getFactor()
        selected.value = 0
      }, err =>{
        err
        toast("Save Gagal", 
        {
          autoClose: 1000,
          type: 'error',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        getFactor()
        selected.value = 0
      })
    }

    const saveFactor = () => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
        saveForm(factor)
      }
    };

    function deleteButton(){
       deleteFactor(selected.value)     
    }

    async function deleteFactor(id){
      await Services.DeleteData(ApiService2, `master/rules-papi-kostick-values/${id}`, response=>{
        getFactor()
        selected.value = 0
      }, err =>{
        err
      })
    }

    return {
      // data,
      factor,
      factorValidator,
      saveFactor,
      submitButton,
      dialogAddGroup,
      deleteButton,
      modalClose,
      factorCache,
      selected,
    };
  },
});
