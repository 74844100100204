import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-body pt-0 pb-15 px-5 px-xl-20" }
const _hoisted_2 = { class: "d-flex flex-column" }
const _hoisted_3 = { class: "row mt-10" }
const _hoisted_4 = { class: "col-lg-6 mb-10 mb-lg-0" }
const _hoisted_5 = { class: "nav flex-column" }
const _hoisted_6 = ["onClick", "data-bs-target"]
const _hoisted_7 = { class: "d-flex align-items-center me-2" }
const _hoisted_8 = { class: "form-check form-check-custom form-check-solid form-check-success me-6" }
const _hoisted_9 = ["value", "checked"]
const _hoisted_10 = { class: "flex-grow-1" }
const _hoisted_11 = { class: "d-flex align-items-center fs-2 fw-bolder flex-wrap" }
const _hoisted_12 = { class: "badge badge-light-danger ms-2 fs-7" }
const _hoisted_13 = { class: "badge badge-light-success ms-2 fs-7" }
const _hoisted_14 = { class: "fw-bold opacity-50" }
const _hoisted_15 = { class: "col-lg-6" }
const _hoisted_16 = { class: "tab-content rounded h-100 bg-light p-10" }
const _hoisted_17 = { class: "pb-5" }
const _hoisted_18 = { class: "fw-bolder text-dark" }
const _hoisted_19 = { class: "pt-1" }
const _hoisted_20 = { class: "mb-10" }
const _hoisted_21 = { class: "fv-plugins-message-container" }
const _hoisted_22 = { class: "fv-help-block" }
const _hoisted_23 = { class: "mb-10" }
const _hoisted_24 = { class: "fv-plugins-message-container" }
const _hoisted_25 = { class: "fv-help-block" }
const _hoisted_26 = { class: "mb-10" }
const _hoisted_27 = { class: "fv-plugins-message-container" }
const _hoisted_28 = { class: "fv-help-block" }
const _hoisted_29 = { class: "mb-10" }
const _hoisted_30 = { class: "fv-plugins-message-container" }
const _hoisted_31 = { class: "fv-help-block" }
const _hoisted_32 = { class: "d-flex justify-content-end" }
const _hoisted_33 = {
  type: "submit",
  id: "kt_minat_bakat_submit",
  ref: "submitButton",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "before-close": _ctx.modalClose,
    modelValue: _ctx.dialogAddGroup,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dialogAddGroup) = $event)),
    title: "",
    width: "80%",
    "align-center": "true"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "mb-13 text-center" }, [
          _createElementVNode("h1", { class: "mb-3" }, "Nilai Faktor")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.factorCache, (factorObj, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    onClick: ($event: any) => (_ctx.selected = factorObj.Id),
                    class: _normalizeClass(["nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6 btn-active btn-active-primary", [
                      index !== _ctx.factorCache.length - 1 && 'mb-6',
                      factorObj.Id == 0 && 'active',
                    ]]),
                    "data-bs-toggle": "tab",
                    "data-bs-target": `#factor_${index}`
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("input", {
                          class: "form-check-input",
                          type: "radio",
                          name: "plan",
                          value: factorObj.Id,
                          checked: _ctx.selected === factorObj.Id
                        }, null, 8, _hoisted_9)
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("h2", _hoisted_11, [
                          _createTextVNode(_toDisplayString(factorObj.Name.toUpperCase()) + " ", 1),
                          _createElementVNode("span", _hoisted_12, _toDisplayString(factorObj.Lower), 1),
                          _cache[7] || (_cache[7] = _createElementVNode("span", { class: "badge badge-light-warning ms-2 fs-7" }, " ~ ", -1)),
                          _createElementVNode("span", _hoisted_13, _toDisplayString(factorObj.Higher), 1)
                        ]),
                        _createElementVNode("div", _hoisted_14, _toDisplayString(factorObj.Des), 1)
                      ])
                    ])
                  ], 10, _hoisted_6))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("h2", _hoisted_18, _toDisplayString(_ctx.factor.Id == 0 ? 'Buat Faktor' : 'Ubah Faktor'), 1)
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_Form, {
                    id: "kt_factor_form",
                    class: "form",
                    novalidate: "novalidate",
                    onSubmit: _cache[5] || (_cache[5] = ($event: any) => (_ctx.saveFactor())),
                    "validation-schema": _ctx.factorValidator
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_20, [
                        _cache[8] || (_cache[8] = _createElementVNode("label", { class: "col-lg-10 col-form-label required fw-bold fs-6" }, "Faktor :", -1)),
                        _createElementVNode("div", null, [
                          _createVNode(_component_Field, {
                            type: "text",
                            name: "fname",
                            class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                            placeholder: "Faktor",
                            modelValue: _ctx.factor.Name,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.factor.Name) = $event))
                          }, null, 8, ["modelValue"]),
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, [
                              _createVNode(_component_ErrorMessage, { name: "fname" })
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_23, [
                        _cache[9] || (_cache[9] = _createElementVNode("label", { class: "col-lg-10 col-form-label required fw-bold fs-6" }, "Nilai Terendah :", -1)),
                        _createElementVNode("div", null, [
                          _createVNode(_component_Field, {
                            type: "number",
                            name: "flower",
                            class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                            placeholder: "Nilai Terendah",
                            modelValue: _ctx.factor.Lower,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.factor.Lower) = $event))
                          }, null, 8, ["modelValue"]),
                          _createElementVNode("div", _hoisted_24, [
                            _createElementVNode("div", _hoisted_25, [
                              _createVNode(_component_ErrorMessage, { name: "flower" })
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_26, [
                        _cache[10] || (_cache[10] = _createElementVNode("label", { class: "col-lg-10 col-form-label required fw-bold fs-6" }, "Nilai Tertinggi :", -1)),
                        _createElementVNode("div", null, [
                          _createVNode(_component_Field, {
                            type: "number",
                            name: "fhigher",
                            class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                            placeholder: "Nilai Tertinggi",
                            modelValue: _ctx.factor.Higher,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.factor.Higher) = $event))
                          }, null, 8, ["modelValue"]),
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("div", _hoisted_28, [
                              _createVNode(_component_ErrorMessage, { name: "fhigher" })
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_29, [
                        _cache[11] || (_cache[11] = _createElementVNode("label", { class: "col-lg-10 col-form-label required fw-bold fs-6" }, "Deskripsi :", -1)),
                        _createElementVNode("div", null, [
                          _createVNode(_component_Field, {
                            as: "textarea",
                            name: "fdes",
                            class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                            placeholder: "Deskripsi",
                            modelValue: _ctx.factor.Des,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.factor.Des) = $event))
                          }, null, 8, ["modelValue"]),
                          _createElementVNode("div", _hoisted_30, [
                            _createElementVNode("div", _hoisted_31, [
                              _createVNode(_component_ErrorMessage, { name: "fdes" })
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_32, [
                        (_ctx.factor.Id != 0)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              type: "reset",
                              class: "btn btn-danger me-2",
                              "data-kt-menu-dismiss": "true",
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteButton()))
                            }, " Hapus "))
                          : _createCommentVNode("", true),
                        _createElementVNode("button", _hoisted_33, _cache[12] || (_cache[12] = [
                          _createElementVNode("span", { class: "indicator-label" }, " Simpan ", -1),
                          _createElementVNode("span", { class: "indicator-progress" }, [
                            _createTextVNode(" Please wait... "),
                            _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                          ], -1)
                        ]), 512)
                      ])
                    ]),
                    _: 1
                  }, 8, ["validation-schema"])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["before-close", "modelValue"]))
}