
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";
import CreatePapiKostickComponent from "@/views/psy/papi-kostick/component/CreatePapiKostickComponent.vue";
import CreatePapiKostickFactorComponent from "@/views/psy/papi-kostick/component/CreatePapiKostickFactorComponent.vue";
import { fa } from "element-plus/es/locale";

export default defineComponent({
  name: "papi-kostick-adm",
  components: {
    CreatePapiKostickComponent,
    CreatePapiKostickFactorComponent,
  },
  setup() {
    let isopenmodal = ref(false)
    let isopenmodalmap = ref(false)
    var isUpdate = ref(false)
    var itemsUpdate = ref({})
    var papiKostickCache = ref([] as Array<any>)
    var papiKostickExampleCache = ref([] as Array<any>)
    const TypeTestID = process.env.VUE_APP_TYPE_TEST_ID_PAPI_KOSTICK

    onMounted(() => {
      setCurrentPageBreadcrumbs("PAPI KOSTICK", []);
      getPapiKostick()
    });

    function openModalAdd(isupdate, itemsupdate){
      isUpdate.value = isupdate
      itemsUpdate.value = itemsupdate
      isopenmodal.value = true
    }

    function openModalMap(){
      isopenmodalmap.value = true
    }

    function isModalClose(val) {
      isopenmodal.value = false
    }

    function isModalMapClose(val){
      isopenmodalmap.value = false
    }

    function isNeedRefresh(val) {
      getPapiKostick()
    }

    async function getPapiKostick(){
      papiKostickCache.value = []
      papiKostickExampleCache.value = []
      await Services.GetData(ApiService2, `master/questions-papi-kostick/masterid`, TypeTestID, response=>{
        for (var i = 0; i < response.data.length; i++) {
          response.data[i].Color = getRandomColor()
          response.data[i].Numb = Number(response.data[i].QuestionNumber)
          if(response.data[i]['IsExample'] == 1){           
            papiKostickExampleCache.value.push(response.data[i])
          }else{
            papiKostickCache.value.push(response.data[i])
          }
        }
        papiKostickCache.value = papiKostickCache.value.groupBy(["Numb"])
        papiKostickCache.value = papiKostickCache.value.orderBy(["Numb"], "ASC")
      }, err =>{
        err
      })
    }

    function getRandomColor() {
      var colorList = ["success", "warning", "primary", "danger", "info"];

      return colorList[Math.floor(Math.random() * 5)]
    }

    function onDelete(val){
      deletePapiKostick(val.QuestionID)
    }

     async function deletePapiKostick(QuestionID){
      await Services.DeleteData(ApiService2, `master/questions-papi-kostick/${QuestionID}`, response=>{
        getPapiKostick()
      }, err =>{
        err
      })
    }

    function onUpdate(val){
      openModalAdd(true, val)
    }

    function formatText(text) {
      // Replace all \r\n and \n with <br> to create line breaks
      return text.replace(/\r?\n/g, '<br>');
    }

    return {
      TypeTestID,
      papiKostickCache,
      papiKostickExampleCache,
      openModalAdd,
      openModalMap,
      isModalClose,
      isModalMapClose,
      isNeedRefresh,
      isopenmodal,
      isopenmodalmap,
      isUpdate,
      itemsUpdate,
      formatText,
      onDelete,
      onUpdate,
    };
  },
});
